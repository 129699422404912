import { MetricsGet } from './gql'

export const CM_BANNER = 'CM_BANNER' // Used for obtaining the value for cmp_visible custom event parameter in GA4
export const CM_ANALYTICS = 'CM_ANALYTICS'
export const CM_PERSONALIZATION = 'CM_PERSONALIZATION'
export const CM_AD = 'CM_AD'
export const CM_SESSION = 'CM_SESSION'

export const cookiesConfig = {
	path: '/',
	httpOnly: false,
	maxAge: 60 * 60 * 24 * 365
}

interface LeadData {
	id?: number
	item?: string
	value?: number
}

export const sendGTM = async (
	event: string,
	{ id, item, value = 100 }: LeadData = {},
	data: object = {}
) => {
	window.dataLayer.push({
		event, // Název události
		...(id && {
			transaction_id: id, // ID transakce
			value // Hodnota objednávky
		}),
		...(item && {
			items: [
				{
					item_name: item // Název produktu, další možnosti: FOTOVOLTAIKA_OFFER
				}
			]
		}), // Pole produktů, v našem případě tam bude 1 produkt
		...(data && { ...data }),
		_clear: true
	})
}

export function getUTMs(searchParams: URLSearchParams) {
	const utm_source = searchParams.get('utm_source')
	const utm_campaign = searchParams.get('utm_campaign')
	const utm_medium = searchParams.get('utm_medium')
	const utm_content = searchParams.get('utm_content')
	const utm_term = searchParams.get('utm_term')

	const utms = {
		...(utm_source && { utm_source }),
		...(utm_campaign && { utm_campaign }),
		...(utm_medium && { utm_medium }),
		...(utm_content && { utm_content }),
		...(utm_term && { utm_term })
	}

	return utms
}

export async function loadLeadUTMs(api, sessionID) {
	try {
		const GTMs = await api.get(MetricsGet, { anonymUid: sessionID })
		const { metricsGet } = GTMs

		const keyMapping = {
			utm_source: 'utmSource',
			utm_medium: 'utmMedium',
			utm_campaign: 'utmCampaign',
			utm_content: 'utmContent',
			utm_term: 'utmTerm'
		}
		const validUTMs = {}
		metricsGet?.content.forEach((item) => {
			if (item.value !== null && keyMapping[item.key]) {
				validUTMs[keyMapping[item.key]] = item.value
			}
		})
		return validUTMs
	} catch (error) {
		console.error('Error loading lead UTMs:', error)
		return {}
	}
}

export const saveLeadGTMs = async (utmParamsEntries: Array<any>): Promise<string> => {
	const response = await fetch(`/api/lead/metrics`, {
		method: 'POST',
		body: JSON.stringify({
			utmParamsEntries: utmParamsEntries
		})
	})

	if (!response.ok) {
		throw new Error(`Fetch error: ${response.statusText}`)
	}

	const responseBodyText = await response.text()
	return responseBodyText
}
